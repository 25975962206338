import { defineComponent } from 'vue'
import { appModule } from '../utils/store-accessor'
import { brandFromSubdomain } from '../utils/brand-helper'

export default defineComponent({
    created() {
        const brand = brandFromSubdomain()
        appModule.setBrandConfigFromName(brand)
    },
})
