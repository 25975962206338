export const brandFromSubdomain = (): string => {
    const subdomain = window.location.hostname.split('.')[0]

    if (subdomain.includes('worksheets')) {
        return 'worksheets'
    } else if (subdomain.includes('bingo')) {
        return 'bingo'
    } else if (subdomain.includes('flashcards')) {
        return 'flashcards'
    } else {
        return 'worksheets'
    }
}
